import React from "react"
import { Link } from "react-router-dom"

const Manleva: React.FC = () => {
  return (
    <div className="container">
      <div className="card d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 my-lg-5 py-5 px-3 px-lg-5 custom-rounded">
        <div className="row">
          <div className="col-12">
            <h2 className="title title dark-color font-33 font-bolder">
              Termini e condizioni di utilizzo per gli Utenti Consumer{" "}
            </h2>
          </div>
          <div className="col-12 dark-color font-16">
            <p>&nbsp;</p>
            <p>
              <strong>Premesse</strong>
            </p>
            <p>
              eShoppingAdvisor.com (di seguito anche “ESA”) è un portale
              specializzato nella gestione delle recensioni di e-commerce e di
              attività commerciali in genere che vendano prodotti e servizi
              online (di seguito, "<em>eshop</em>").
              <br /> eShoppingAdvisor.com ed i siti ad esso collegati (come
              eShoppingAdvisor.it, trust.eshoppingadvisor.com,
              business.eshoppingadvisor.com, blog.eshoppingadvisor.com,
              ecommercesicuro.com, di seguito “<em>Sito web</em>”) si rivolgono
              a tutti gli <em>eshop</em> presenti in rete e "attivi" nel
              commercio di qualsiasi prodotto e/o servizio e a tutti i loro
              clienti attuali o potenziali.
            </p>
            <p>
              Si prega di leggere attentamente i presenti Termini e Condizioni
              di utilizzo per gli Utenti Consumer (di seguito, “
              <em>Condizion</em>i”) prima di utilizzare il Sito web e si
              consiglia di stamparne una copia.
            </p>
            <p>
              La registrazione e l’accettazione espressa delle presenti
              Condizioni sono presupposti necessari per l’utilizzo del Sito web.
              Diversamente, non si è autorizzati all’utilizzo del presente sito.
            </p>
            <p>
              Completando la procedura di registrazione l’utente dichiara di
              avere letto ed accettato tutte le condizioni, i termini e le
              modalità di utilizzo e di fruizione dei servizi offerti da
              eShoppingAdvisor.com.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>1) INFORMAZIONI RELATIVE A ESHOPPINGADVISOR</strong>
            </p>
            <p>
              Il sito, il dominio e il marchio eShoppingAdvisor sono di
              proprietà di World Advisor S.r.l. in persona del legale
              rappresentante <em>pro tempore</em>, con sede in Selargius (CA),
              Via Peretti 2B (Italia – 09047), P.IVA 13826511001, iscrizione REA
              CA-301042, Capitale sociale e quota versata 17,664,04 €.
            </p>
            <p>
              World Advisor S.r.l. è il titolare o il licenziatario di tutti i
              diritti di proprietà industriale ed intellettuale relativi al Sito
              web, ai suoi contenuti e ai materiali ivi pubblicati (a titolo
              esemplificativo e non esaustivo, articoli di informazione,
              fotografie, illustrazioni, immagini, video) ad eccezione dei nomi
              di dominio (URL) degli eshop, dei loro marchi, disegni, progetti,
              del contenuto delle recensioni e qualsiasi altro contenuto
              inserito spontaneamente dall’Utente mediante la sua recensione o
              inseriti nel Sito web in seguito alla registrazione da parte
              dell’eshop con conseguente sottoscrizione di un contratto Free,
              Company e Pro.
            </p>
            <p>
              I diritti di proprietà industriale ed intellettuale di proprietà
              di World Advisor S.r.l. sono oggetto di tutela ai sensi delle
              leggi applicabili.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>2) OGGETTO</strong>
            </p>
            <p>
              Le presenti Condizioni stabiliscono le modalità attraverso le
              quali è consentito utilizzare il Sito web, le recensioni e le
              eventuali iniziative commerciali reperibili sul portale ESA.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>3) UTENTE CONSUMER</strong>
            </p>
            <p>
              Registrandoti come Utente Consumer dichiari di agire come
              consumatore per scopi estranei da qualsiasi attività di impresa e
              professionale eventualmente svolta ed accetti di usufruire dei
              servizi offerti dal Sito web mediante l’inserimento delle
              recensioni riguardanti la Tua esperienza di acquisto presso un{" "}
              <em>eshop</em>.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>4) OBBLIGAZIONI DELL’UTENTE CONSUMER</strong>
            </p>
            <p>
              <strong>4.1.</strong> Con la registrazione e, dunque, con
              l’accettazione delle presenti Condizioni dichiari di accettare
              anche:
            </p>
            <p>
              <em>(i) </em>Informativa sul trattamento dei dati personali che
              stabilisce le modalità e le finalità di trattamento dei dati
              personali raccolti dal Sito web. Utilizzando il Sito web l’Utente
              acconsente al trattamento dei dati personali dallo stesso forniti;
              (link cliccabile)
            </p>
            <p>
              <em>(ii) </em>Cookie Policy che contiene informazioni sui cookie
              utilizzati dal Sito web. (link cliccabile)
            </p>
            <p>
              <strong>4.2.</strong> Con la registrazione del profilo, Ti impegni
              a fornire un indirizzo email vero e personale nonché un nick che
              non è offensivo e/o contrario al buon costume e/o in violazione di
              nomi e diritti personali di terzi. Ti impegni altresì a non
              registrare più profili con l’uso di altri contatti email o
              contatti social duplicati o riferiti a persone inesistenti e da te
              gestiti anonimamente.
            </p>
            <p>
              <strong>4.3.</strong> Con la registrazione ed il caricamento delle
              recensioni, Ti impegni a non fornire dati falsi e/o documenti
              contraffatti, falsi o che sai essere falsi e contraffatti così
              come Ti impegni a non fornire esperienze di acquisto inesistenti o
              fatte da terzi o non vissute personalmente.
            </p>
            <p>
              <strong>4.4.</strong> Con la registrazione, Ti impegni a mantenere
              riservata la password di accesso al Tuo profilo. Il Tuo profilo è
              personale e non deve essere trasferito ad altri.
            </p>
            <p>
              <strong>4.5.</strong> Il nome Utente e la password di accesso al
              Sito web sono riservati e non devono essere comunicati a terzi, né
              utilizzati per scopi non autorizzati e/o illeciti. Qualora dovessi
              smarrire la password puoi usufruire del servizio “recupera
              password” e riceverai un codice di identificazione ed una password
              temporanea via email. Tale password temporanea dovrai cambiarla
              immediatamente al primo accesso e tali informazioni dovranno
              essere trattate con cura e non dovranno essere divulgate a terzi.
              Per informazioni sulla tutela dei tuoi dati personali ti inviamo a
              leggere qui (link cliccabile che rinvia all’informativa).
            </p>
            <p>
              <strong>4.6.</strong> Qualora dovessi venire a conoscenza o
              sospettassi che un altro soggetto si sia impossessato dei Tuoi
              dati di accesso al Sito web sei tenuto a darne immediata
              comunicazione a World Advisor S.r.l. inviando una e-mail al
              seguente indirizzo:{" "}
              <Link to="mailto:info@eshoppingadvisor.com">
                info@eshoppingadvisor.com
              </Link>{" "}
              e cambiando immediatamente la password.
            </p>
            <p>
              <strong>4.7.</strong> Sei direttamente responsabile dei contenuti
              generati e pubblicati sul Sito web (recensioni) e garantisci che
              gli stessi sono corretti e veritieri poiché corrispondono ad
              esperienze personali e dirette di acquisito tramite un qualsiasi
              negozio virtuale.
            </p>
            <p>
              <strong>4.8.</strong> Con il caricamento della recensione consenti
              a World Advisor S.r.l. l’insindacabile ed autonoma facoltà di
              acquisire, registrare e pubblicare la recensione riconoscendo che
              tale recensione viene acquisita a titolo gratuito e senza
              corrispettivo con rinuncia – fin da ora - a qualsiasi compenso,
              rimborso spese. Tu potrai dialogare con altri utenti o rispondere
              ai commenti connessi alla Tua recensione.
            </p>
            <p>
              <strong>4.9.</strong> Non potrai caricare recensioni di{" "}
              <em>e-shop</em> che offrono prodotti/servizi rientranti nelle
              seguenti categorie:{" "}
              <strong>
                <em>(a)</em>
              </strong>{" "}
              pornografia o prodotti e servizi vietati ai minori di 18 anni;{" "}
              <strong>
                <em>(b)</em>
              </strong>{" "}
              commercino di armi da fuoco o da taglio e di qualsiasi altro
              genere che non rispettino le norme e i regolamenti dei paesi di
              riferimento (ad es. siti di gioco che in Italia non operino con
              Concessione ADM);{" "}
              <strong>
                <em>(c) </em>
              </strong>
              commercio di narcotici, steroidi e sostanze stupefacenti di ogni
              genere;{" "}
              <strong>
                <em>(d) </em>
              </strong>
              commercio di specie animali autoctone, protette e in pericolo di
              estinzione;{" "}
              <strong>
                <em>(e) </em>
              </strong>
              eventuali altre tipologie non previste ma che contrastino con lo
              spirito del Sito web o che in qualche modo siano considerati
              offensivi.
            </p>
            <p>
              <strong>4.10. </strong>Non potrai recensire e-shop che vengono
              gestiti direttamente da Te o partecipati da Te e direttamente ed
              indirettamente.
            </p>
            <p>
              <strong>4.11. </strong>Qualora sia necessario accertare
              l’esistenza di una violazione alle leggi applicabili posta in
              essere da Te o in caso di violazione dei diritti di proprietà
              intellettuale o di altra natura in relazione ai contenuti
              caricati, World Advisor S.r.l. potrà rivelare la Tua identità
              anche ai titolari di tali diritti. Ciò anche nell’ipotesi di
              recensioni contenenti espressioni offensive, false e non
              rispondenti a vere esperienze di acquisto.
            </p>
            <p>
              <strong>4.12. </strong> Potrai stampare una copia delle pagine del
              Sito web per il Tuo uso personale o per condividerli con altre
              persone all'interno della Tua sfera di amicizie ma a condizione
              che World Advisor S.r.l. venga sempre indicata come autrice del
              materiale di cui sopra. È fatto divieto, dunque, di
              modificare/alterare il materiale scaricato dal Sito web e
              utilizzare immagini, fotografie, sequenze video o audio o
              qualsiasi grafica separatamente dal testo che le accompagna o
              violare il diritto di terzi.
            </p>
            <p>
              <strong>4.13. </strong>Nell’ipotesi in cui dovessero sorgere dubbi
              sulla correttezza e veridicità della Tua recensione, fin da ora,
              su richiesta della World Advisor S.r.l. ti impegni a fornire le
              prove e comunque i dettagli che possano essere utili a dimostrare
              che l’esperienza di acquisto recensita corrisponde ad una
              effettiva e reale esperienza vissuta personalmente.
            </p>
            <p>
              <strong>4.14. </strong>Prendi atto e riconosci che eventuali
              sconti e buoni acquisto sono concessi da partner della World
              Advisor S.r.l. e Ti impegni ad usare sconti e buoni nel rispetto
              delle norme di utilizzo previste per ciascuno di essi dai singoli
              partner.
            </p>
            <p>
              <strong>4.15.</strong>Dichiari e garantisci che i contenuti da Te
              generati e pubblicati sul Sito web (recensioni) sono corretti e
              veritieri e non violano il diritto d’autore di terzi essendo gli
              stessi elaborati personalmente.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>5) IMPEGNI DI WORLD ADVISOR</strong>
            </p>
            <p>
              <strong>5.1.</strong> World Advisor S.r.l., fatto salvo quanto
              previsto all’art. 5.3., 6.4. e 7, si impegna a consentirti la
              fruizione del portale e l’accessibilità del tuo Account nonché a
              consentirti il caricamento delle Tue recensioni personali e dei
              documenti che comprovano l’acquisto.
            </p>
            <p>
              <strong>5.2.</strong> World Advisor S.r.l. dichiara e garantisce
              la piena libertà di espressione nei limiti del rispetto del
              diritto d’immagine di terzi e nei limiti in cui Tu rispetti le
              indicazioni e le clausole di cui al precedente art. 4.
            </p>
            <p>
              <strong>5.3. </strong>World Advisor S.r.l. si riserva il diritto
              di disattivare in qualsiasi momento il nome utente e la{" "}
              <em>password</em> scelti da Te (o assegnati da World Advisor
              S.r.l.) se, a suo giudizio, Tu non hai rispettato le presenti
              Condizioni o le Condizioni Aggiuntive o hai commesso violazioni
              alle leggi applicabili.
            </p>
            <p>
              <strong>5.4. </strong>World advisor S.r.l. avrà facoltà di
              comunicare alla parte interessata o alle autorità competenti i
              Tuoi dati in caso di violazione del diritto all’immagine o falsità
              delle affermazioni o condotta denigratoria o anticoncorrenziale,
              salva la possibilità di darne previa informazione a Te per le
              relative giustificazioni.
            </p>
            <p>
              <strong>5.5.</strong> In caso di caricamento della prova di
              acquisto, World Advisor si impegna a segnalare la Tua recensione
              come recensione certificata. Le recensioni vengono certificate con
              apposito bollino anche quando vengono scritte a seguito di un
              invito da parte dell'e-commerce nel quale hai acquistato.
            </p>
            <p>
              <strong>5.6.</strong> World Advisor S.r.l. si impegna ad assegnare
              alla Tua recensione il punteggio di 0,25 per ogni recensione e di
              1 per ogni recensione certificata (cfr. art. 5.5. che precede) e,
              conseguentemente, si impegna a redigere ed aggiornare la
              classifica dei recensori.
            </p>
            <p>
              <strong>5.7.</strong> World Advisor S.r.l. si impegna a informarTi
              sulle novità nella sezione "Coupon", presente dentro l'area
              riservata del Tuo profilo. Nella Tua area riservata troverai il
              tuo saldo punti e la lista dei coupon sconto sbloccati, come se
              fosse un vero e proprio estratto conto. Inoltre, troverai tutti i
              numerosi coupon presenti e potrai fare una ricerca per "nome
              e-commerce" o per categoria.
            </p>
            <p>
              <strong>5.8.</strong> World Advisor S.r.l. si impegna a rendere
              reperibili esclusivamente gli e-commerce caricati dagli utenti
              come Te o quelli dei titolari che hanno sottoscritto la
              registrazione come utenti Business. World Advisor si impegna
              altresì a pubblicare la recensione su e-commerce da te indicati.
            </p>
            <p>
              <strong>5.9. </strong>World Advisor S.r.l. si impegna a rendere
              reperibile la Tua recensione e consente l’interazione di utenti,
              titolari di e-shop e visitatori con la Tua recensione attraverso
              anche l’espressione di “like” o di risposte alle tue recensioni.
            </p>
            <p>
              <strong>5.10. </strong>World Advisor S.r.l. ha il diritto di
              utilizzare, pubblicare, distribuire e divulgare a terzi per scopi
              connessi al servizio fornito tutte le tue recensioni. Oltre ad
              evoluti sistemi di controllo e filtro presenti sul sito, lo staff
              di eShoppingAdvisor ha facoltà di verificare periodicamente le
              recensioni inserite anche ai fini del rispetto delle presenti
              Condizioni.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>6) RESPONSABILITÀ DELL’UTENTE CONSUMER </strong>
            </p>
            <p>
              <strong>6.1.</strong> Tu sei responsabile nei confronti di World
              Advisor S.r.l. per tutti i danni che la stessa potrebbe subire in
              relazione ai contenuti e ai materiali caricati da Te sul Sito web.
            </p>
            <p>
              <strong>6.2. </strong>Tu sei tenuto ad inserire recensioni che
              siano in linea con gli scopi e gli obiettivi del Sito web e, ferma
              restando la libertà di espressione e di informazione (potrai
              liberamente recensire un eshop nei limiti delle categorie vietate
              indicate al punto 4.9), World Advisor S.r.l. fa affidamento sulla
              correttezza e sul buon senso degli utenti e, tal fine, è vietato
              pubblicare sul sito web recensioni aventi contenuti che:{" "}
              <strong>
                <em>(i) </em>
              </strong>
              siano illeciti, ingannevoli, fuorvianti, fraudolenti, minacciosi,
              abusivi, molesti, calunniosi, diffamatori, lesivi, osceni,
              pornografici o blasfemi;{" "}
              <strong>
                <em>(ii) </em>
              </strong>
              abbiano carattere sessista, politico o razziale;{" "}
              <strong>
                <em>(iii) </em>
              </strong>
              ledano i diritti di altre persone, inclusi diritti di proprietà
              intellettuale;{" "}
              <strong>
                <em>(iv)</em>
              </strong>{" "}
              siano offensivi o violino leggi o normative locali, nazionali o
              internazionali;{" "}
              <strong>
                <em>(v)</em>
              </strong>{" "}
              abbiano finalità di propaganda politica;{" "}
              <strong>
                <em>(vi)</em>
              </strong>{" "}
              abbiano finalità commerciale o di marketing;{" "}
              <strong>
                <em>(vii)</em>
              </strong>{" "}
              violino le presenti Condizioni e le Condizioni aggiuntive;{" "}
              <strong>
                <em>(viii)</em>
              </strong>{" "}
              abbiano uno scopo o un contenuto sleale o illecito o promuovano
              scopi illeciti (a titolo esemplificativo, le norme sul diritto
              d’autore;{" "}
              <strong>
                <em>(ix)</em>
              </strong>{" "}
              siano tecnicamente dannosi (quali, a titolo esemplificativo, virus
              informatici, trojan, worm, dati corrotti o altro software
              maligno). Tale elenco ha mera finalità esemplificativa e non
              esaustiva e le violazioni di queste Condizioni comportano la non
              approvazione o la rimozione dei commenti inviati dagli utenti, a
              insindacabile giudizio dello staff di eShoppingAdvisor e senza
              preavviso, ferma ogni azione per il risarcimento di ogni danno.
            </p>
            <p>
              <strong>6.3.</strong> Se utilizzi il Sito web ovvero stampi, copi
              o scarichi pagine, stralci, video, immagini, fotografie ecc in
              violazione delle presenti Condizioni, World Advisor S.r.l. potrà
              richiederti di distruggere o di restituire il materiale
              illegittimamente utilizzato, salvo il risarcimento del danno.
            </p>
            <p>
              <strong>6.4.</strong> Accetti e garantisci da qualsiasi danno in
              caso di pubblicazione di recensioni, opinioni, commenti di
              contenuto vietato ed espressamente indicati come vietati e
              qualsiasi recensione che contenga insulti, epiteti, o qualsiasi
              parola e/o frase ritenuta ad insindacabile giudizio dagli
              amministratori di eShoppingAdvisor inadatte, sconvenienti,
              moralmente inaccettabili, discriminatorie in qualsiasi forma o
              lesive dell'immagine di eShoppingAdvisor. Detti messaggi verranno
              eliminati non appena possibile.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>7) LIMITAZIONI E VERIFICA DELLE RECENSIONI</strong>
            </p>
            <p>
              Le recensioni spontanee verranno validate solo qualora descrivano
              nei dettagli una reale esperienza di acquisto o qualora venga
              caricata (<em>upload</em>) una prova di acquisto. In assenza di
              uno di questi due parametri le recensioni possono non essere
              pubblicate, ad insindacabile giudizio dello staff di
              eShoppingAdvisor.
            </p>
            <p>
              World Advisor S.r.l. adotta misure tecniche (controlli automatici
              delle recensioni in base a chi ha effettuato un acquisto reale)
              nonché misure manuali (attraverso la moderazione delle recensioni,
              effettuata da un team di persone, che permette di trovare ed
              eliminare eventuali recensioni non veritiere o false) per
              verificare l'autenticità delle recensioni e per garantire che le
              recensioni pubblicate sulla piattaforma eShoppingAdvisor
              provengano esclusivamente da utenti consumer che hanno
              effettivamente utilizzato o acquistato beni o servizi. Queste
              recensioni sono contrassegnate come "Recensione certificata" e
              sono le uniche ad essere prese in considerazione nel punteggio
              complessivo.
            </p>
            <p>
              al fine di garantire la provenienza delle recensioni, World
              Advisor S.r.l. richiede la registrazione e la verificabilità
              dell’utente consumer che intende effettuare una recensione.
            </p>
            <p>
              Nell’ipotesi di dubbi circa la veridicità di una recensione, il
              team di World Advisor S.r.l. provvederà a richiedere prove di
              legittimità delle recensioni all’’utente consumer e, nel caso in
              cui queste prove non dovessero essere fornite, la recensione verrà
              sospesa ed eventualmente cancellata.Possono essere effettuate
              segnalazioni delle recensioni nei seguenti casi:
            </p>
            <ul>
              <li>in caso di recensione offensiva o con contenuto illegale;</li>
              <li>
                in caso di violazione delle condizioni di utilizzo di
                eShoppingAdvisor;
              </li>
              <li>
                quando è possibile dimostrare che parti della recensione non
                sono veritiere.
              </li>
            </ul>
            <p>
              Le recensioni pubblicate sulla piattaforma eShoppingAdvisor sono
              quindi conformi alle disposizioni contenute nella Direttiva
              Omnibus (2161/2019), che ha stabilito nuove norme in tema di
              recensioni degli e-commerce.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>8) MANLEVA ED ESONERO DI RESPONSABILITA’</strong>
            </p>
            <p>
              <strong>8.1. </strong>La società World Advisor S.r.l. non si
              assume nessuna responsabilità nei confronti di terzi per i
              contenuti pubblicati da Te (utente) all’interno del Sito web e non
              fornisce alcuna garanzia rispetto alle recensioni e, più in
              genere, ai contenuti pubblicati, archiviati o caricati dall'utente
              nella piattaforma e-ShoppingAdvisor nell'eventualità di danni e/o
              in caso di errori, diffamazioni, ingiurie, calunnie, omissioni,
              false dichiarazioni, linguaggio osceno. Ricoprendo la qualità di
              fornitore di servizi interattivi, World Advisor S.r.l., sebbene
              non sia soggetta ad alcun obbligo di verificare, analizzare,
              rimuovere, modificare o monitorare i contenuti pubblicati dagli
              utenti, si riserva il diritto, a sua esclusiva discrezione, di
              verificare, analizzare, tradurre o modificare senza preavviso
              qualsiasi contenuto pubblicato o archiviato sulla piattaforma
              e-ShoppingAdvisor in qualsiasi momento o per qualsiasi motivo, o
              di consentire tali operazioni a terze parti per proprio conto.
            </p>
            <p>
              <strong>8.2. </strong>Le opinioni e i commenti espressi dagli
              Utenti non
              <strong>
                {" "}
                rappresentano le opinioni di World Advisor S.r.l..
              </strong>
            </p>
            <p>
              <strong>8.3. L</strong>'Utente che pubblica le proprie recensioni
              sulla professionalità, celerità e affidabilità dell’<em>eshop</em>{" "}
              dal quale ha acquistato, accetta di manlevare, mantenere indenne e
              indennizzare World Advisor S.r.l., i suoi collaboratori, agenti e
              dipendenti da qualsiasi obbligo risarcitorio verso terzi derivante
              da o in qualsiasi modo collegato all'utilizzo del servizio
              eShoppingAdvisor da parte dello stesso utente, incluse eventuali
              responsabilità o spese legali derivanti da qualsiasi richiesta di
              risarcimento, perdita, danno (diretto o indiretto), causa,
              sentenza, processo e consulenza legale, di qualsiasi tipo e natura
              avanzati da terze parti e risultanti da:{" "}
              <strong>
                <em>(a)</em>
              </strong>{" "}
              violazione delle presenti Condizioni e delle Condizioni aggiuntive
              da parte dell'utente;{" "}
              <strong>
                <em>(b)</em>
              </strong>{" "}
              violazione di leggi o di diritti di terze parti da parte
              dell'utente;
              <strong>
                <em>(c)</em>
              </strong>{" "}
              uso non corretto dei Servizi da parte dell'utente.
            </p>
            <p>
              <strong>8.4. </strong>Nel caso indicato al precedente art. 8.3.,
              World Advisor S.r.l. provvederà a comunicare per iscritto
              all'Utente tale richiesta di risarcimento, causa o azione legale.
            </p>
            <p>
              <strong>8.5. </strong>Dichiari inoltre che le recensioni che
              pubblicherai su eShoppingAdvisor.com&nbsp;saranno frutto della Tua
              personale esperienza di acquisto, e sei consapevole del fatto che
              il servizio eShoppingAdvisor ha l’obiettivo di garantire
              l’autenticità delle recensioni pubblicate e che le recensioni
              mendaci sono vietate e violano specifiche norme di Legge in
              diverse giurisdizioni.
            </p>
            <p>
              <strong>8.6. </strong>Potrai generare link a siti web di terze
              parti ma è esclusiva responsabilità Tua assumere precauzioni per
              garantire che tali collegamenti siano privi di elementi quali
              virus, cavalli di Troia, difetti e altri elementi di natura
              dannosa. L'inclusione dei link a siti web non implica in alcun
              modo la sponsorizzazione da parte di eShoppingAdvisor ma tale
              inclusione viene prevista esclusivamente per comodità e a scopo di
              riferimento e World Advisor S.r.l. non esercita nessun tipo di
              controllo su tali siti e non è responsabile dei contenuti o della
              tutela della privacy o altre prassi di tali siti web.
            </p>
            <p>
              <strong>8.7. </strong>La società World Advisor S.r.l. non è
              affiliata, collegata o contrattualmente legata ad alcuna delle
              pagine web raggiungibili mediante il link “Vai al Sito” presenti
              all’interno del Sito web. Un link dal Sito web ad un qualsiasi
              altro sito Web non implica che World Advisor S.r.l. approvi,
              sponsorizzi o raccomandi in alcun modo detto sito web ovvero che
              ne sia proprietario, licenziatario o che ne abbia il controllo.
              Anche in presenza di tale link, World Advisor S.r.l. non si assume
              alcuna responsabilità per i contenuti e/o l’uso presenti
              all’interno del sito raggiungibile tramite il link “
              <em>vai al sito</em>”.
            </p>
            <p>
              <strong>8.8. </strong>World Advisor S.r.l. non fornisce
              indicazioni sulla affidabilità degli e-shop limitandosi alla
              acquisizione delle recensioni di utenti terzi e all’utilizzo di
              strumenti automatici di classificazione e di punteggio medio sulla
              base del numero di recensioni e del voto di ciascuna recensione.
            </p>
            <p>
              <strong>8.9. </strong>World Advisor S.r.l. non è responsabile
              delle condizioni di vendita e delle condizioni contrattuali
              connesse a buoni sconto o promozioni dei singoli e-shop e Tu
              dovrai verificare con il singolo e-shop condizioni e termini delle
              vendite e dei relativi buoni sconto. World Advisor, pertanto, non
              propone al pubblico e non promuove e non svolge alcuna
              intermediazione alla vendita per nessuno dei prodotti venduti dai
              singoli e-shop che, difatti, potranno essere acquistati
              direttamente presso i singoli esercenti.
            </p>
            <p>
              8.10. World Advisor S.r.l. non è responsabile delle recensioni
              importate su richiesta degli utenti business, le quali vengono
              segnalate con apposito simbolo e con l’indicazione della fonte.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                9) MODIFICHE UNILATERALI DELLE PRESENTI CONDIZIONI
              </strong>
            </p>
            <p>
              World Advisor S.r.l. si riserva a propria discrezione, in
              qualsiasi momento e senza preavviso, la facoltà di rivedere o
              modificare, in tutto o in parte, le presenti Condizioni, e le
              Condizioni aggiuntive per motivi tecnici, di adeguamento normativo
              o anche al fine di riorganizzare il sito web.
            </p>
            <p>
              L’Utente registrato accetta le presenti Condizioni per l’utente
              nella loro versione in vigore. Di ogni revisione o modifica delle
              Condizioni per l’utente verrà data tempestiva comunicazione sul
              sito web o tramite newsletter. L’Utente dichiara di accettare che
              la prosecuzione nell’utilizzo del sito web dopo la pubblicazione
              di una versione modificata delle Condizioni e delle Condizioni
              Aggiuntive costituisce accettazione di tale versione.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>10) DURATA E RISOLUZIONE</strong>
            </p>
            <p>
              Tu hai il diritto ad accedere ed utilizzare i servizi offerti sul
              sito web in qualsiasi momento per qualsiasi motivo e senza
              incorrere in alcuna responsabilità, a condizione che ciò avvenga
              nel rispetto delle presenti Condizioni e Condizioni Aggiuntive.
            </p>
            <p>
              Qualora l’utente decida di cancellare il proprio profilo, tutti i
              diritti qui concessi all’utente cesseranno immediatamente.
            </p>
            <p>
              World Advisor S.r.l. ha facoltà di bloccare l’accesso e cancellare
              il Tuo Account in caso di violazione delle norme indicate nelle
              presenti Condizioni agli art. 4, 6 e 8.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11) LEGGE APPLICABILE E GIURISDIZIONE COMPETENTE</strong>
            </p>
            <p>
              Le Condizioni per l’utente sono soggette alla legge italiana la
              quale si applica a tutti i rapporti tra World Advisor S.r.l. e gli
              Utenti del sito web.
            </p>
            <p>
              Qualsiasi controversia, azione legale, o altra questione derivante
              o connessa all’ uso del sito sarà soggetta alla legge italiana
              senza alcuna applicazione dei principi di diritto internazionale
              privato.
            </p>
            <p>
              L’utente dichiara inoltre di accettare che qualsiasi controversia
              che dovesse insorgere con riferimento alla validità, efficacia ed
              interpretazione delle presenti Condizioni e delle Condizioni
              Aggiuntive è devoluta in via esclusiva al Tribunale di Roma.
            </p>
            <p>&nbsp;</p>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Manleva;